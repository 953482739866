@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700');
@import './constants.scss';
h1, h2, h3, h4, h5, h6, p, li, input, textarea, label, button, table, footer, figcaption, span {
    color: $blackish;
    font-family: 'Quicksand';
}
h1 {
    margin: 0px 0px 20px 0px;
    font-size: 2.8em;
    font-weight: 700;
    letter-spacing: 1.3px;
    text-align: center;
    @media(min-width: $medium) {
        margin: 0px 0px 15px 0px;
        font-size: 3.8em;
    }
}
h2 {
    font-size: 2.2em;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
    @media(min-width: $medium) {
        font-size: 3.2em;
        font-weight: 500;
        margin: 0px 0px 25px 0px;
    }
}
h3 {
    font-weight: 300;
    font-size: 1.6em;
    margin: 0px 0px 15px 0px;
    @media(min-width: $medium){
        font-weight: 500;
        font-size: 2.3em;
        margin: 0px 0px 25px 0px;
    }
    @media(min-width: $large){
    }
}
th {
    font-weight: 300;
    font-size: 1.2em;
    margin: 0px 0px 15px 0px;
    @media(min-width: $medium){
        font-weight: 500;
        font-size: 1.6em;
        margin: 0px 0px 25px 0px;
    }
    @media(min-width: $large){
    }
}
h4 {
    font-weight: 400;
    font-size: 1.1em;
    margin: 20px 0px 5px 0px;
    @media(min-width: $medium){
        font-size: 1.2em;
        margin: 0px 0px 25px 0px;
    }
    @media(min-width: $large){
        font-size: 1.3em;
    }
}
p, li, td, .footer-links {
    font-size: 1.05em;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 20px;
    @media(min-width: $medium) {
        font-size: 1.15em;
        font-weight: 400;
        letter-spacing: 0.02em;
        line-height: 1.5;
        margin-bottom: 25px;
    }
}
a {
    color: $blue;
    font-weight: 700;
}
input[type='text'], input[type='email'], input[type='password'], textarea {
    font-family: 'Quicksand';
    font-size: 1.2em;
    font-weight: 500;
    @media(min-width: $medium) {
        margin-bottom: 25px;
    }
}
footer {
    font-size: 0.85em;
}
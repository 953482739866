@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700');
@import '../constants.scss';

$button-height: 60px;

.button {
    position: relative;
    overflow: hidden;
    margin-bottom: 25px;
    min-height: $button-height;
}

input[type='submit'], button, .button-link {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 10px 30px 10px 30px;
    font-family: 'Quicksand';
    font-size: 1.2em;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    transition: background .2s ease;
    &.colour-button {
        background: $blue;
        color: white;
        border-radius: 999px;
        &:hover {
            background: adjust-hue($blue,10deg);
        }
        &:active {
            background: adjust-hue($blue,20deg);
        }
    }
    &.square-button {
        background: none;
        color: $blue;
        border: solid 4px $blue;
        border-radius: 2px;
        &:hover {
            background: $blue;
            color: white;
        }
        &:active {
            background: $blue;
            color: white;
        }
    }
}
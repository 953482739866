$trailing: 10px;
$blackish: #0B3C49;
$purple: #564787;
$blue: #00A6FB;
$primary3: #EA526F;
$secondary1: #73EEDC;
$secondary2: #97CC04;
$light-grey: #4D7985;
$very-light-grey: #E6F0F2;

$medium: 500px;
$large: 1000px;
$maximum-content-width: 1200px;
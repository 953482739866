table {
    border: 2px solid #00A6FB;
}
th {
    padding: 9px 5px 9px 5px;
    background: #00A6FB;
    color: white;
}
tr:nth-child(2n+1) {
    background: rgba(#00A6FB,0.1);
}
td {
    padding: 7px 5px 7px 5px;
}
@import 'shared/normalise.scss';
@import 'shared/constants.scss';
@import 'shared/copy.scss';
@import 'shared/table.scss';
@import 'shared/container.scss';
@import 'shared/subscription.scss';

.body {
    margin-top: 80px;
    @media(min-width: $medium){
        margin-top: 50px;
    }
}

.error, .destroy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .container {
        width: 90%;
        @media(min-width: $medium) {
            width: 400px;
        }
        h1 {
            margin-bottom: 20px;
        }
        a {
            color: $purple;
            &:hover {
                color: lighten($purple, 20%);
            }
        }
    }
}

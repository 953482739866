html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, 
p, blockquote, pre, a, abbr, acronym, address, big, cite, code, 
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, 
sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, 
fieldset, form, label, legend, table, caption, tbody, tfoot, 
thead, tr, th, td, article, aside, canvas, details, embed, figure, 
figcaption, footer, header, hgroup, menu, nav, output, ruby, 
section, summary, time, mark, audio, video { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    color: black;
    font-size: 100%; 
    font-style: normal;
    font-weight: normal;
    text-align: left;
    vertical-align: baseline; 
    cursor: default;
} 

article, aside, details, figcaption, figure, footer, header, 
hgroup, menu, nav, section {
    display: block; 
} 

body {
    line-height: 1.2; 
} 

h1, h2, h3, h4, h5, h6, p, li {
    color: black;
    font-size: 100%; 
    font-style: normal;
    font-weight: normal;
    text-align: left;
}

a {
    text-decoration: none !important;
    color: inherit;
    cursor: pointer;
}

ol { 
    padding-left: 0em; 
    list-style: none; 
} 

ul { 
    padding-left: 0em; 
    list-style: none;
    overflow: hidden;
    width: 100%;
} 

table { 
    border-collapse: collapse; 
    border-spacing: 0;
    overflow: hidden;
}
hr {
    margin: 0px;
    border: none;
}
textarea, input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
    background: none;
    color: black;
    resize: none;
    border: none;
    padding: 0;
    font-size: 100%; 
    font-style: normal;
    font-weight: normal;
    text-align: left;
    &:focus {
        outline: none;
    }
    &::-webkit-inner-spin-button {
        -webkit-appearance: none; 
        margin: 0; 
    }
    &::-webkit-outer-spin-button {
        -webkit-appearance: none; 
        margin: 0; 
    }
}
input[type='submit'], button, form {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
    background: none;
    border: none;
    &:focus {outline:none;}
    &::-moz-focus-inner {border:0;}
}